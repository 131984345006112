import React from 'react';
import clsx from 'clsx';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default ({
  savings = false,
  value,
  text,
  unit = '',
}) => (
  <div className={clsx(styles.container,
    savings && styles.selectedContainer)}>
    <Typography variant='h3'
      align='left'
      weight='bold'
      className={clsx(
        styles.valueText,
        savings && styles.selected
      )}>
      {Math.floor(value)} <span className={clsx(styles.unitText, savings && styles.unitSelected)}>{unit}</span>
    </Typography>
    <Typography variant='h5'
      align='left'
      weight='light'
      className={clsx(
        styles.textContainer,
        savings && styles.textSelected,
        savings && styles.selected
      )}>
      {text}
    </Typography>
  </div>
);
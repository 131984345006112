import React from 'react';
import Content from '../views/ROI/Logbook';

export default ({location}) => (
  <Content location={location} seo={{
    title: 'Reducer omkostninger ved at benytte en elektronisk kørebog',
    lang: 'da-DK',
    description: 'Beregn din årlige besparelse med vores ROI beregner. Find ud af hvor meget du kan spare med OBI+ ved at eliminere manuelt kørselsregistrering.',
    meta: [{
      name: 'keywords',
      content: 'elektronisk kørebog'
    }]
  }}/>
);